.linkWindow {
    display: flex;
    background-color: white;
    margin-left: 10px;
    justify-content: left;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .dateLinks{
    font-weight: 700;
    margin-bottom: 10px;    
  }
  .dateLinksSpan{
    margin-right: 10px;
    cursor: pointer;
    margin-left: 10px;
    color: #000;
  }
  .dateLinksSpanActive {
    margin-right: 10px;
    cursor: pointer;
    margin-left: 10px;
    color: #0000b3;
    padding-bottom: 10px;
    border-bottom: 2px solid #0000b3;
  }