.fixed-startDate{
    position: sticky !important;
    left: 0;
    z-index: 5;
    background: #f7eeee;
}
.rdt_TableHeadRow:first-child {
    position: sticky !important;
    left: 0;
    z-index: 5;
    background: #f7eeee;
}