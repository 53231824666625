@tailwind base;
@tailwind components;
@tailwind utilities;

.sim-table input[type=checkbox][disabled]{
    background-color: grey;
    border: none;
    opacity: 0.7;
}

.sim-table input[type=checkbox]{
    border: 1px solid black;
}
.fixed-radio label span {
    font-size: 11px !important;
}