.table{
    background: #fff;
    border: 1px solid #ccc;
    table-layout: fixed;
    width: 100% !important;
}
.customerTable{
    background: #fff;
    border: 1px solid #ccc;
}
.cellDataKey{
    border-left: 1px solid #ccc !important;
    text-transform: capitalize!important;
    font-size: 0.75rem !important;
    padding: 5px !important;
    line-height: 0.9rem !important;
    max-width: 20rem !important;
    width: 20rem;
    min-width: 20rem !important;
}
.cellDataPromotions{
    border-left: 1px solid #ccc !important;
    text-transform: capitalize!important;
    font-size: 0.75rem !important;
    padding: 5px !important;
    line-height: 0.9rem !important;
    max-width: 12rem !important;
    width: 12rem;
    min-width: 12rem !important;
}
.cellDataRIR{
    border-left: 1px solid #ccc !important;
    text-transform: capitalize!important;
    font-size: 0.75rem !important;
    padding: 5px !important;
    line-height: 0.9rem !important;
    max-width: 20rem !important;
    width: 20rem;
    min-width: 20rem !important;
}
.cellWithNoData{
    border-left: 1px solid #ccc !important;
    text-transform: capitalize!important;
    font-size: 0.75rem !important;
    font-weight: bold;
    padding: 5px !important;
    line-height: 0.9rem !important;
}
.cellData{
    border-left: 1px solid #ccc !important;
    text-transform: capitalize!important;
    font-size: 0.75rem !important;
    padding: 5px !important;
    line-height: 0.9rem !important;
}
.cellDataCustomer{
    border-left: 1px solid #ccc !important;
    text-transform: capitalize!important;
    font-size: 0.75rem !important;
    padding: 5px !important;
    line-height: 0.9rem !important;
    max-width: 11rem !important;
    width: 11rem;
    min-width: 11rem !important;
}
.cellDataTitle , .headerCellTitle{
    border-left: 1px solid #ccc !important;
    text-transform: capitalize!important;
    font-size: 0.75rem !important;
    padding: 5px !important;
    line-height: 0.9rem !important;
    max-width: 8rem !important;
    width: 8rem;
    min-width: 8rem !important;
}
.headerCell{
    border-left: 1px solid #ccc !important;
    text-transform: capitalize !important;
    position: sticky;
    top:25px;
    font-size: 0.75rem !important;
    width:5rem !important;
    min-width:5rem !important;
    max-width:5rem !important;
    z-index: 2;
    background-color: #fff;
    padding: 5px !important;
    line-height: 0.9rem !important;    
}
.headerCellSmall{
    border-left: 1px solid #ccc !important;
    text-transform: capitalize !important;
    position: sticky;
    top:25px;
    font-size: 0.75rem !important;
    width:5rem !important;
    min-width:5rem !important;
    max-width:5rem !important;
    z-index: 2;
    background-color: #fff;
    padding: 5px !important;
    line-height: 0.9rem !important;    
}
.childCell{
    border-left: 1px solid #ccc !important;
    font-size: 0.75rem !important;
    text-align: right !important;
    z-index: 0;
    width:5rem !important;
    min-width:5rem !important;    
    max-width:5rem !important; 
    background-color: rgb(237, 241, 241);
    padding: 5px !important;
    line-height: 0.9rem !important; 
}
.cell{
    border-left: 1px solid #ccc !important;
    font-size: 0.75rem !important;
    text-align: right !important;
    z-index: 0; 
    width:5rem !important;
    min-width:5rem !important;    
    max-width:5rem !important;    
    padding:2px !important;
    line-height: 0.9rem !important; 
}
.childSmallCell{
    border-left: 1px solid #ccc !important;
    font-size: 0.75rem !important;
    text-align: right !important;
    z-index: 0;
    width:5rem !important;
    min-width:5rem !important;
    max-width:5rem !important;
    background-color: rgb(237, 241, 241);
    padding: 5px !important;
    line-height: 0.9rem !important; 
}
.smallCell{
    border-left: 1px solid #ccc !important;
    font-size: 0.75rem !important;
    text-align: right !important;
    z-index: 0;
    width: 2rem !important;
    min-width: 2rem !important;
    max-width: 2rem !important;
    padding: 5px !important;
    line-height: 0.9rem !important;    
}
.headerCellMid{
    border-left: 1px solid #ccc !important;
    text-transform: capitalize !important;
    position: sticky;
    top:25px;
    font-size: 0.75rem !important;
    width: 3rem !important;
    min-width: 3rem !important;
    max-width: 3rem !important;
    z-index: 2;
    background-color: #fff;
    padding: 5px !important;
    line-height: 0.9rem !important;    
}
.childMidCell{
    border-left: 1px solid #ccc !important;
    font-size: 0.75rem !important;
    text-align: right !important;
    z-index: 0;
    background-color: rgb(237, 241, 241);
    width: 3rem !important;
    min-width: 3rem !important;
    max-width: 3rem !important;
    padding: 5px !important;
    line-height: 0.9rem !important;
}

.midCell{
    border-left: 1px solid #ccc !important;
    font-size: 0.75rem !important;
    text-align: right !important;
    z-index: 0;
    width: 3rem !important;
    min-width: 3rem !important;
    max-width: 3rem !important;
    padding: 5px !important;
    line-height: 0.9rem !important;    
}

.totalCell{
    border-left: 1px solid #fff !important;
    font-size: 0.75rem !important;
    position:sticky;
    top:60px;
    background-color: #ccc;  
    text-align: right !important;
    padding: 5px !important;
    line-height: 0.9rem !important; 
       
}

.titleCell{
    border-left: 1px solid #ccc !important;
    font-size: 0.75rem !important;
    max-width: 8rem !important;
    width: 8rem;
    min-width: 8rem !important;
    padding: 5px !important;
    line-height: 0.9rem !important;
    
}
.childTitleCell{
    border-left: 1px solid #ccc !important;
    font-size: 0.75rem !important; 
    background-color: rgb(237, 241, 241); 
    text-align: right !important; 
    justify-self: right;
    width: 8rem !important;
    max-width: 8rem !important;
    min-width: 8rem !important;
    padding: 5px !important;
    line-height: 0.9rem !important;
}
.totalTitleCell{
    border-left: 1px solid #fff !important;
    font-size: 0.75rem !important;
    position:sticky;
    top:60px;
    background-color: #ccc;
    padding: 5px !important;
    line-height: 0.9rem !important;      
}

.expandBtn{
    margin: 0px 5px;
    font-size: 1.45rem;
    padding-top: 0;
    line-height: 0.1rem;
}
.titleLabel{
    padding-top:0px;

}