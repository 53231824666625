
.react-dropdown-tree-select .dropdown .dropdown-trigger {
  width: 100%;
  height: 73px;
}

.react-dropdown-tree-select .dropdown .dropdown-content {
  z-index: 0;
  background: transparent;
  border-top: none;
  box-shadow: none;
}

.root .node-label {
  font-weight: 600;
  color: #2663ff;
}

.root .node > label {
  padding: 5px;
  text-transform: capitalize;
}
.root .node > label:hover {
  background-color: rgba(216, 213, 213, 0.6);
}

.Embed-container {
  width: 100%;
  height: 650px;
}

.Embed-container-page {
  width: 100%;
  height: 390px;
}

.Embed-container-promo {
  width: 100%;
  height: 290px;
}

.node.disabled > * {
  cursor: pointer;
}

.root .node > label:hover {
  background-color: none;
}

.elasticity
  .react-dropdown-tree-select
  .dropdown
  .tag-list
  .tag-item:not(:last-child) {
  display: none;
}

.makeStyles-iconButton-127:hover {
  background-color: none;
}
.backButton {
  padding-top: 50px;
  border-bottom: 1px solid #e8e8e8;
  padding-left: 25px;
}

.rdrMonthsVertical{
  background: #fff;
  z-index: 2;
}

.kcOvalShape {
    font-weight: bold;
    padding: 5px;
    color: #FFFFFF;
    background: #1450a9;
    border-radius: 10px;
}

.targetOvalShape {
    font-weight: bold;
    padding: 5px;
    color: #FFFFFF;
    background: #A26CBC;
    border-radius: 10px;
}

.rdt_TableHeader  {
  display:  none !important;
}

.rdt_TableHead  .rdt_TableCol_Sortable{
  font-weight: 600;
  font-size: 16px;
}

.tableBld {
  font-weight: 600
}

.rdt_TableCol_Sortable span{

  margin-left: 0px;
  /* position: absolute;
    right: 9px;
    top: 11px; */
}


.rdt_TableRow:hover{
  background-color: #f5f2f2 !important;
}


.DateRangePicker{
  top: -6px;
}

.DateRangePicker .DateRangePickerInput__withBorder{ 
  border: 0;
}

.MuiDialog-paperWidthSm{
  min-width: 500px;
  max-width: 1200px;
}

.hideCheckbox .checkbox-item{
  display: none
}


.DateRangePickerInput, .DateInput .DateInput_input{
  background-color: #F6F8FC !important;
  background: #F6F8FC !important;
}

.MuiButton-root:hover{
  background-color: #000 !important;
}

.Embed-containerr-promo{
  height: 280px;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded{
  margin: 0;
}


.MuiPaper-root .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded{
  min-height: 40px;
}

.MuiPaper-root .css-o4b71y-MuiAccordionSummary-content.Mui-expanded{
  margin: 0;
}

.MuiAccordionDetails-root{
  padding: 8px 16px 0px;
}

.MuiButtonBase-root-150.promo-chips{
  margin: 3px 0;
  justify-content: space-between;
}

.rounded-full.promo-chip-main{
  background: #1350a9;
  padding: 3px 9px;
 
}

.rounded-full.promo-chip-main.chips-list{
  margin: 3px 0;
}
.rounded-full.promo-chip-main span.value{
  border-radius: 12px;
    background: #fff;
    color: #1350a9;
    padding: 3px 10px;
    margin: 0 7px;
    min-height: 26px;
}

.promo-cross{
  position: relative;
    top: 0px;
}

.css-15v22id-MuiAccordionDetails-root{
  padding: 8px 16px 0px !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded{
  margin: 0 !important;
}

.downArrow{
  position: relative;
  top: 3px;
}

input[type="date"]::-webkit-calendar-picker-indicator{
 
  background-image: url('./images/calendar.svg');
}


.makeStyles-container-1 {
  height: auto !important;
 
}
.filter-customer-channel-div {
  overflow-y: scroll;
  min-height: 100px;
  max-height: 400px;
}
.export_icon {
  float: right;
  right: 15px;
  position: absolute;
}
.KR-PostROI-Dashboard-Reports .Embed-container {
  height: 365px;
}
.KR-PostROI-Summary-Reports .Embed-container{
  height: 60vh;
  overflow: hidden;
}
.KR-PostROI-PriceVolume-Reports .Embed-container{
  height: 560px;
  overflow: hidden;
}