.dropdown-content {
    overflow-x: hidden;
    min-height: 400px;
    width: 100%;
    overflow-y: auto;
    height: 400px;
    margin-top: 10px;
}

.react-dropdown-tree-select {
    height: 3rem;
}

.react-dropdown-tree-select .dropdown .dropdown-content {
    z-index: 0;
    background: transparent;
    border-top: none;
    box-shadow: none;
}

.tag-list .tag-item .tag {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
}

.tag-remove {
    margin-left: 6px;
    color: red;
    margin-right: 5px;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger {
    border: none;
    padding-bottom: 0px;
}

.react-dropdown-tree-select .dropdown {
    width: 100%;
}

.react-dropdown-tree-select .dropdown {
    width: 100%;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow {
    width: inherit;
    display: inline-flex;
}

.tag-list {
    display: inline-block;
    width: inherit;
    position: relative;
}

.tag-item .search {
    width: inherit;
    background: #fafafa;
    padding: 0 5px;
}

.tag-item:last-child {
    margin-right: 4px;
    /* position: fixed; */
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    top: 0;
    display: flex;
    height: 25px;
    width: 388px;
    border-bottom: 0.1px solid #dad7d7;
}

.makeStyles-root-37 {
    background: #F6F8FC
}

.MuiTable-root .MuiTable-root {
    background: inherit;
}

.MuiTable-root {
    border-collapse: collapse;
}

.MuiTableCell-body {
    padding: 0;
}

.node.disabled>* {
    cursor: pointer;
}

.infinite-scroll-component {
    overflow: visible;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
    content: "\25BC";
    vertical-align: middle;
    color: #3c3c3c;
    margin-right: 2px;
    width: 400px;
    display: flex;
    position: absolute;
    margin-left: 351px;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
    content: "\25B2";
    vertical-align: middle;
    color: #3c3c3c;
    margin-right: 2px;
    /* width: 400px; */
    display: flex;
    position: absolute;
    margin-left: 351px;
}

