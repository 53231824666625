.rmsc .dropdown-container {
  background-color: inherit;
  border: none;
  border-bottom: 1px solid #808080;
  border-radius: 0px;
}

.rmsc .dropdown-container:focus-within {
  box-shadow: none;
  border-color: #808080;
}

.rmsc .dropdown-container > .dropdown-content {
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2);
  margin: 0px;
  padding-top: 2px;
  height: 300px;
  z-index: 999;
}